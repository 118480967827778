import Rx from 'rxjs'
import QueryRemoteReducer$ from './QueryRemoteReducer'
import ErrorHandlerReducer$ from './ErrorHandlerReducer'

const reducer$ = Rx.Observable.merge(
  QueryRemoteReducer$.map(reducer => ['queryRemote', reducer]),
  ErrorHandlerReducer$.map(reducer => ['appError', reducer])
)

export default reducer$
