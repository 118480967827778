import { connect } from '../state/RxState'
import actions from '../actions/errorHandlerActions'
import ErrorHandler from '../components/ErrorHandler'
import { onlyUpdateForKeys } from 'recompose'

const mapper = ({ appError }) => ({
  appError
})

export default connect(mapper, { ...actions })(
  onlyUpdateForKeys(['appError'])(ErrorHandler)
)
