import * as qs from 'qs';
import { state, hoc } from '../../../fe-core';
import { compose } from 'recompose';

import RegisterByInviteForm from '../components/RegisterByInviteForm';
import registerActions from '../actions/register';
import userActions from '../actions/user';

const { withStartAction } = hoc;

const mapper = state => ({
  register: state.register
});

export default compose(
  state.RxState.connect(mapper, {
    ...registerActions,
    setUsernameLogin: userActions.setUsername,
    addMessageToShowLogin: userActions.addMessageToShow
  }),
  withStartAction(
    ({ location = {}, initializeValidation, saveInviteParameters }) => {
      const parsedQueryParams = qs.parse(location.search || '', {
        ignoreQueryPrefix: true
      });
      initializeValidation();
      saveInviteParameters(parsedQueryParams);
    }
  )
)(RegisterByInviteForm);
