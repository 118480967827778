import RestService from './rest.service'
import * as qs from 'qs'
import { Observable } from 'rxjs/Observable'

class QueryService extends RestService {
  queryRemote(params) {
    const dtFormat = this.toDatatableQuery(params)
    const query = qs.stringify(dtFormat)
    const url = this.apiUrl + params.url + '?' + query
    return this.get(url).map(this.handleDtQueryResponse(params))
  }
  createObj({ url: baseUrl, ...body }) {
    return this.post(this.apiUrl + baseUrl, body)
  }
  deleteObj(props) {
    const { apiUrl: baseUrl, id } = props
    if (id) {
      return this.delete(this.apiUrl + baseUrl + '/' + id)
    }
    return Observable.throw('deleteObj: missing id field')
  }
}

export default new QueryService()
