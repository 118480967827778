import React from 'react'

import PropTypes from 'prop-types'

import './PageContainer.css'

const PageContainer = props => {
  return <div className='pageContainer'>{props.children}</div>
}

PageContainer.propTypes = {
  children: PropTypes.node
}

export default PageContainer
