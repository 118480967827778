import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { AuthorizationError, ApiError, ClientError } from '../lib/errorUtil'

const ErrorHandler = ({ appError, resetError }) => {
  if (!appError) {
    return null
  }
  
  if (appError instanceof AuthorizationError) {
    return (
      <SweetAlert
        info
        confirmBtnText='Ok'
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='default'
        title='Access denied'
        onConfirm={resetError}
      >
        Unauthorized access, please log in.
      </SweetAlert>
    )
  }

  if (appError instanceof ApiError) {
    const msg = appError.info ? appError.info.msg : ''
    const defaultMsg = 'unknown error'
    return (
      <SweetAlert
        info
        confirmBtnText='Ok'
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='default'
        title='Api Error'
        onConfirm={resetError}
      >
        Remote service error, please try again later.
        <hr />
        <p>
          <em>Code</em>: {appError.httpCode}
        </p>
        {msg &&
          msg !== defaultMsg && (
          <p>
            <em>Message</em>: {appError.info ? appError.info.msg : '-'}
          </p>
        )}
      </SweetAlert>
    )
  }

  if (appError instanceof ClientError) {
    const msg = appError.info ? appError.info.msg : ''
    const defaultMsg = 'unknown error'
    const messageFound = msg && msg !== defaultMsg
    return (
      <SweetAlert
        info
        confirmBtnText='Ok'
        confirmBtnBsStyle='primary'
        cancelBtnBsStyle='default'
        title='Client Error'
        onConfirm={resetError}
      >
        {messageFound && <p>{msg}</p>}

        {!messageFound && (
          <p>
            We're sorry, but you got an error from our server. This can be
            caused by your parameters, please check your request and try again
            later.
          </p>
        )}

        <hr />
        <p>
          <em>Code</em>: {appError.httpCode}
        </p>
      </SweetAlert>
    )
  }

  return (
    <SweetAlert
      info
      confirmBtnText='Ok'
      confirmBtnBsStyle='primary'
      cancelBtnBsStyle='default'
      title='Server error'
      onConfirm={resetError}
    >
      An error occured
    </SweetAlert>
  )
}
export default ErrorHandler
