/* eslint no-template-curly-in-string: 0 */

const baseConfig = {
  _ok: 'ok', // for unittest
  environment: 'dev',

  sentryDsn: 'https://b6f3068f21ef41e6b63e7ce2ce17349e@sentry.io/1298277',
  registerUserOpen: false
};

const handler = {
  get: (obj, prop) => {
    if (!prop) {
      return;
    }

    const envKeyName = 'REACT_APP_' + prop.toUpperCase();
    if (envKeyName in process.env) {
      return process.env[envKeyName];
    }

    if (prop in baseConfig) {
      return baseConfig[prop];
    }

    return;
  }
};

const config = new Proxy(baseConfig, handler);


export default config;
