import React from 'react'
import { FormGroup, InputGroup, Input, FormFeedback } from 'reactstrap'

import PropTypes from 'prop-types'

import styles from './InputRowWithIcon.css'

const InputRowWithIcon = props => {
  const {
    RowIcon,
    placeholder,
    name,
    type,
    value,
    onChange,
    validFunc,
    errorFeedback,
    showValidity,
    showFeedback,
    iconCssClass,
    inputCssClass
  } = props
  return (
    <FormGroup className='mt-4'>
      <InputGroup>
        <div className='input-group-prepend'>
          <span
            className={
              'input-group-text' + (iconCssClass ? ' ' + iconCssClass : '')
            }
          >
            <RowIcon size={24} />
          </span>
        </div>
        <Input
          className={inputCssClass}
          type={type}
          placeholder={placeholder}
          name={name}
          id={name}
          onChange={onChange}
          value={value}
          invalid={showValidity ? !validFunc() : null}
          valid={showValidity ? validFunc() : null}
        />
        <FormFeedback>{showFeedback ? errorFeedback : ''}</FormFeedback>
      </InputGroup>
    </FormGroup>
  )
}
InputRowWithIcon.defaultProps = {
  validFunc: () => true,
  type: 'text',
  errorFeedback: '',
  showValidity: false,
  showFeedback: true,
  iconCssClass: styles.halfTransparentInput,
  inputCssClass: styles.halfTransparentInput
}
InputRowWithIcon.propTypes = {
  RowIcon: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'email']),
  value: PropTypes.string,
  onChange: PropTypes.func,
  validFunc: PropTypes.func,
  errorFeedback: PropTypes.string,
  showValidity: PropTypes.bool,
  showFeedback: PropTypes.bool,
  iconCssClass: PropTypes.string,
  inputCssClass: PropTypes.string
}

export default InputRowWithIcon
