import * as qs from 'qs';
import { state, hoc } from '../../../fe-core';
import { compose } from 'recompose';

import VerifyEmailView from '../components/VerifyEmail';
import registerActions from '../actions/register';

const { withStartAction } = hoc;

const mapper = state => ({
  register: state.register,
  user: state.user
});

export default compose(
  state.RxState.connect(mapper, {
    ...registerActions
  }),
  withStartAction(({ location = {}, verifyEmail }) => {
    const parsedQueryParams = qs.parse(location.search || '', {
      ignoreQueryPrefix: true
    });
    verifyEmail(parsedQueryParams);
  })
)(VerifyEmailView);
