import React from 'react';
import { Container, Row, Col, Card } from 'reactstrap';

export default props => {
  const { qai } = props;

  return (
    <Container qai={qai}>
      <Row className="justify-content-center">
        <Col className="p-4" xs="10" sm="8" md="6">
          <Card className="dark-blue-card mt-4 p-4" body>
            {props.children}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
