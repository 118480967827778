import React from 'react'
import { MdErrorOutline } from 'react-icons/md'

import './ErrorComponent.css'

// FIXME: when sentry is available look here:
// https://blog.sentry.io/2017/09/28/react-16-error-boundaries

export default props => (
  <div className='error'>
    <MdErrorOutline color='white' size='70' />

    <p>We're sorry - something's gone wrong.</p>

    {/* <p>Our team has been notified, but click here fill out a report.</p> */}
  </div>
)
