import Confirm from '../components/Confirm';
import registerActions from '../actions/register';
import { state } from '../../../fe-core';

const mapper = state => ({
  register: state.register
});

export default state.RxState.connect(mapper, {
  ...registerActions
})(Confirm);
