import React from 'react'

import './LoadingIndicator.css'

export default props => {
  const { mx = '64px', color = '#fff' } = props
  const styleColor = {
    borderColor: `${color} transparent transparent transparent`
  }
  return (
    <div className='loader' style={{ marginTop: mx, marginBottom: mx }}>
      <div className='lds-ring'>
        <div style={styleColor} />
        <div style={styleColor} />
        <div style={styleColor} />
        <div style={styleColor} />
      </div>
    </div>
  )
}
