import LoginForm from '../components/LoginForm';
import userActions from '../actions/user';
import { state, hoc } from '../../../fe-core';
import { compose } from 'recompose';

const { withStartAction } = hoc;

const mapper = state => ({
  user: state.user,
  register: state.register
});

export default compose(
  state.RxState.connect(mapper, {
    ...userActions
  }),
  withStartAction(({ initializeValidation }) => {
    initializeValidation();
  })
)(LoginForm);
