import { state } from '../../../fe-core';

export default state.RxState.createActions([
  'registerUser',
  'setUsername',
  'setPassword',
  'setPasswordConfirm',
  'setEmail',
  'setShowError',
  'initializeValidation',
  'clearState',
  'verifyEmail',
  'sendAgainConfirmEmail',
  'sendResetPassword',
  'verifyResetPassword',
  'resetPassword',
  'saveInviteParameters',
  'registerUserByInvitation'
]);
