import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, Alert } from 'reactstrap';
import { MdPermIdentity, MdLock } from 'react-icons/md';
import { components } from '../../../fe-core';

import LoginStylePage from './LoginStylePage';
import { REGISTEROK, RECOVERYOK } from '../reducers/constants';
import config from '../../../configs';

import './LoginForm.css';

const { HRWithText, ButtonRedirect, InputRowWithIcon } = components;

const MyLoginForm = props => {
  const {
    login,
    setUsername,
    setPassword,
    user: {
      username,
      password,
      validationError = {}, // client side validation error
      validated, // client side validation error
      showError
    } = {},
    setShowError
  } = props;

  const validField = err => {
    const error = err ? err.msg : false;
    if (error && err && err.type === 'critical') return false;
    return showError ? !error : undefined;
  };
  const getErrorMsg = err => (err ? err.msg : null);

  const formSubmit = ev => {
    ev.preventDefault();
    //const data = new FormData(ev.target);
    //const username = data.get('username');
    //const password = data.get('password');
    //login({ username, password });

    if (!validated) {
      setShowError(true);
    } else {
      login({ username, password });
    }
  };
  return (
    <Form onSubmit={formSubmit}>
      

      <InputRowWithIcon
        name="username"
        placeholder="Username"
        RowIcon={MdPermIdentity}
        onChange={setUsername}
        value={username}
        validFunc={() => validField(validationError.username)}
        showValidity={showError}
        errorFeedback={getErrorMsg(validationError.username)}
        showFeedback={showError}
      />

      <InputRowWithIcon
        name="password"
        type="password"
        placeholder="Password"
        RowIcon={MdLock}
        onChange={setPassword}
        value={password}
        validFunc={() => validField(validationError.password)}
        showValidity={showError}
        errorFeedback={getErrorMsg(validationError.password)}
        showFeedback={showError}
      />

      <Button qai="s" className="float-left mt-2" color="primary">
        Login
      </Button>

      <ButtonRedirect
        to="/recovery"
        qai="fp"
        className="float-right"
        color="link"
      >
        <small> Forgot your password?</small>
      </ButtonRedirect>
    </Form>
  );
};

class LoginForm extends Component {
  render() {
    const {
      clearError,
      removeMessageShowed,
      user: {
        isLogged,
        loginError, // server side error on login
        messagesIds = []
      } = {}
    } = this.props;
    const { from } = this.props.location.state || {
      from: { pathname: '/' },
      justRegistered: false
    };

    // if is already logged or the login process
    //  is completed go to route from which we arrived to login
    if (isLogged) {
      return <Redirect to={from} />;
    }

    let showMessageRegistered = false;
    if (messagesIds.indexOf(REGISTEROK) !== -1) {
      showMessageRegistered = true;
      removeMessageShowed({ msgId: REGISTEROK, timeout: 50000 });
    }

    let showMessageRecovery = false;
    if (messagesIds.indexOf(RECOVERYOK) !== -1) {
      showMessageRecovery = true;
      removeMessageShowed({ msgId: RECOVERYOK, timeout: 50000 });
    }

    //if (justRegistered && registeredUsername) {
    //  setUsername({currentTarget: {value: registeredUsername}});
    //}

    return (
      <LoginStylePage qai="page-login" key="loginCard">
        <h3>Login</h3>
        {loginError ? (
          <Alert className="mt-4" color="danger">
            Invalid username or password
          </Alert>
        ) : showMessageRegistered ? (
          <Alert className="mt-4" color="info">
            Account created please login with your credentials
          </Alert>
        ) : showMessageRecovery ? (
          <Alert className="mt-4" color="info">
            Password reset done, please login with your new credentials
          </Alert>
        ) : (
          ''
        )}

        <MyLoginForm className="p-3" {...this.props} />

        {config.registerUserOpen && <HRWithText text="" />}

        {config.registerUserOpen && (
          <ButtonRedirect
            onClick={clearError}
            to="/register"
            qai="r"
            color="link"
          >
            New to InsurEngines? Register Now!
          </ButtonRedirect>
        )}
      </LoginStylePage>
    );
  }
}

export default LoginForm;
