import Rx from 'rxjs'
import actions from '../actions/errorHandlerActions'

const initialState = null

const ErrorHandler$ = Rx.Observable.of(() => initialState).merge(
  actions.resetError.map(() => _state => null),
  actions.setError.map(err => _state => err)
)

export default ErrorHandler$
