import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'reactstrap'

// milliseconds to wait before showing the tooltip when hovering an element
const DEFAULT_SHOW_DELAY = 700
const DEFAULT_HIDE_DELAY = 20

const withTooltip = WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props)

      this.toggle = this.toggle.bind(this)
      this.state = {
        tooltipOpen: false
      }
    }

    toggle() {
      this.setState({
        tooltipOpen: !this.state.tooltipOpen
      })
    }
    componentDidUpdate(prevProps, prevState) {
      // if disabled change when tooltip was open
      if (
        !prevProps.disabled &&
        this.props.disabled &&
        this.state.tooltipOpen
      ) {
        // force close tooltip
        this.toggle()
      }
    }
    render() {
      const {
        datatoggle,
        placement = 'auto',
        id,
        cssClassWrapper = '',
        showDelayMs = DEFAULT_SHOW_DELAY,
        hideDelayMs = DEFAULT_HIDE_DELAY,
        disabled,
        inline = false
      } = this.props

      if (id && datatoggle && datatoggle !== '') {
        const BaseComponents = [
          <WrappedComponent {...this.props} id={id} />,
          <Tooltip
            placement={placement}
            isOpen={this.state.tooltipOpen}
            target={id}
            autohide={false}
            toggle={disabled ? () => null : this.toggle}
            delay={{ show: showDelayMs, hide: hideDelayMs }}
            // popperOptions={{positionFixed: true}}
          >
            {datatoggle}
          </Tooltip>
        ]

        if (inline) {
          return BaseComponents
        }

        return <div className={cssClassWrapper}>{BaseComponents}</div>
      } else {
        return <WrappedComponent {...this.props} />
      }
    }
  }
}
withTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  datatoggle: PropTypes.string,
  placement: PropTypes.string,
  cssClassWrapper: PropTypes.string,
  showDelayMs: PropTypes.number,
  hideDelayMs: PropTypes.number
}
export default withTooltip
