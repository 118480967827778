import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

import withTooltip from '../hoc/withTooltip'

import IconOnly from './IconOnly'

const ButtonIcon = props => {
  const {
    text,
    iconDisplay,
    icon: IconComp,
    disabled,
    small,
    large,
    iconOnly = false,
    ...other
  } = props
  const styles = {
    span: {
      left: { marginLeft: '10px' },
      right: { marginRight: '10px' }
    },
    container: {},
    button: {
      cursor: disabled ? 'auto' : 'pointer'
    }
  }
  const display = iconDisplay !== 'right' ? 'left' : 'right'
  const Icon = IconComp ? (
    <IconComp style={{ verticalAlign: 'text-top' }} />
  ) : null
  const Text = text ? <span style={styles.span[display]}>{text}</span> : null
  const size = small ? 'sm' : large ? 'lg' : ''

  if (iconOnly) {
    return <IconOnly {...props} />
  }

  return (
    <Button size={size} disabled={disabled} style={styles.button} {...other}>
      <div style={styles.container}>
        {Icon}
        {Text}
      </div>
    </Button>
  )
}

ButtonIcon.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  iconDisplay: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  iconOnly: PropTypes.bool,
  onClick: PropTypes.func
}

export default withTooltip(ButtonIcon)
