import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { IconContext } from 'react-icons';
// import { ApolloProvider } from 'react-apollo';
import { state } from './fe-core';
import Rx from 'rxjs';
import { setObservableConfig } from 'recompose';
import rxjsConfig from 'recompose/rxjsObservableConfig';

import reducer$ from './reducers';
import Configs from './configs';
// import ApolloClient from './state/ApolloClient';

// import './index.css';
// import './bootstrap.css';


// this need to be imported after main csses
import App from './modules/limbo/App';

const {Provider, createState} = state.RxState;

if (Configs.environment !== 'dev') {
  Sentry.init({
    dsn: Configs.sentryDsn,
    environment: Configs.environment,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
  });
}

// configure recompose to use rxjs without passing from central state
setObservableConfig(rxjsConfig);

const initialState$ = Rx.Observable.of({});

ReactDOM.render(
  // <ApolloProvider client={ApolloClient}>
  <Provider state$={createState(reducer$, initialState$)}>
    <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
      <App />
    </IconContext.Provider>
  </Provider>
  // </ApolloProvider>
  ,
  document.getElementById('root')
);
