import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './AppContainer.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// legacy core css
import './core.css'

const AppContainer = props => {
  return (
    <Router>
      <div className={
        classnames(
          'AppContainer',
          'h-100',
          'd-flex',
          'flex-column',
          'flex-grow'
        )}
      >
        {props.children}
      </div>
    </Router>
  )
}

AppContainer.propTypes = {
  children: PropTypes.node
}

export default AppContainer
