import { withStateHandlers, withProps, compose } from 'recompose'

import { isEmpty } from '../lib/lodash'

const validate = ({ curr: element, validator }) => {
  const validationErrors = {}
  Object.keys(validator).forEach(k => {
    // test if is valid
    const failTest = validator[k].find(t => !t.validate(element[k], element))
    if (failTest) {
      const { msg: testMsg, type = 'normal' } = failTest
      const msg =
        typeof testMsg === 'function' ? testMsg(element[k], element) : testMsg

      validationErrors[k] = { msg, type }
    }
  })
  const validated = isEmpty(validationErrors)
  return {
    validationErrors,
    validated
  }
}

const initialState = {
  validated: null,
  validationErrors: {},
  showStatus: false,
  submitted: false,
  changed: false
}
const withValidation = compose(
  withStateHandlers(
    props => ({
      validator: props.validator,
      formatter: props.formatter,
      curr: { ...props.element },
      original: { ...props.element },
      defaults: { ...props.defaults },
      ...initialState,
      ...validate({
        curr: { ...props.element },
        validator: props.validator
      })
    }),
    {
      setValue: props => (key, value) => {
        props.curr[key] = value
        return {
          ...props,
          ...validate(props),
          changed: true,
          submitted: false
        }
      },
      submitForm: props => (ev, callback) => {
        ev.preventDefault()
        // if already submitted dont do anything
        if (!props.changed) return props

        const validateRes = validate(props)
        const validated = validateRes.validated

        if (validated && callback) {
          callback(props.formatter ? props.formatter(props.curr) : props.curr)
        }

        return {
          ...props,
          showStatus: true,
          submitted: validated,
          changed: !validated,
          ...validateRes
        }
      },
      resetForm: props => () => {
        return {
          validator: props.validator,
          curr: { ...props.original },
          original: { ...props.original },
          ...initialState
        }
      }
    }
  ),
  withProps(props => ({
    ...props,
    isValidField: field =>
      props.showStatus
        ? !props.validationErrors[field]
        : props.validationErrors[field] &&
          props.validationErrors[field].type === 'critical'
          ? false
          : null,
    isInvalidField: field =>
      props.showStatus
        ? !!props.validationErrors[field]
        : props.validationErrors[field] &&
          props.validationErrors[field].type === 'critical'
          ? true
          : null,
    getErrorMsg: field =>
      props.validationErrors[field] ? props.validationErrors[field].msg : '',
    getValue: field => props.curr[field],
    getDefault: field => props.defaults[field]
  }))
)

export default withValidation
