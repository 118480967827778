import React from 'react'
import PropTypes from 'prop-types'

import './HRWithText.css'

const component = props => <hr className='hr-text' data-content={props.text} />

component.propTypes = {
  text: PropTypes.string
}

export default component
