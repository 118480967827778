import React from 'react';
import { Button, Alert } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { components, hoc } from '../../../fe-core';

import LoginStylePage from './LoginStylePage';

import { RECOVERYOK } from '../reducers/constants';

const { ValidatedInput, ValidatedForm } = components;
const { withLoading } = hoc;

const createValidator = () => ({
  password: [
    {
      validate: val => !!val && val.trim().length >= 8,
      msg: 'password must have at least 8 characters'
    },
    {
      validate: val => !!val && val.trim().length < 128,
      msg: 'password must be less than 128 characters'
    }
  ],
  passwordConfirm: [
    {
      validate: (val, others) => val === others.password,
      msg: "confirm password didn't match password"
    },
    {
      validate: (val, others) => val !== '',
      msg: 'confirm password cannot be empty'
    }
  ]
});

const RecoveryDoForm = props => {
  const {
    register: { err, loading, resetPasswordParameters, passwordUpdated } = {},
    resetPassword,
    addMessageToShowLogin
  } = props;

  const onSubmit = el => {
    resetPassword({ ...resetPasswordParameters, ...el });
  };

  // if is already logged or the login process
  //  is completed go to route from which we arrived to login
  if (passwordUpdated) {
    // save registration completed message on login form
    addMessageToShowLogin(RECOVERYOK);

    return <Redirect to="/login" />;
  }

  return (
    <LoginStylePage qai="page-recovery-do">
      <h3>Reset password</h3>

      {err && (
        <Alert className="m-3 p-3" color="danger">
          An error occured: {err.msg}
        </Alert>
      )}

      {!err && (
        <ValidatedForm onSubmit={onSubmit} validator={createValidator()}>
          <ValidatedInput
            fieldName="password"
            label="Password"
            type="password"
            id="password"
            placeholder={'Password'}
          />
          <ValidatedInput
            fieldName="passwordConfirm"
            label="Confirm password"
            type="password"
            id="passwordConfirm"
            placeholder={'Confirm password'}
          />
          <Button color="primary" type="submit" disabled={loading}>
            reset password
          </Button>{' '}
        </ValidatedForm>
      )}
    </LoginStylePage>
  );
};

export default withLoading(props => props.register.loading)(RecoveryDoForm);
