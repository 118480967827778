import React from 'react';

import { Route, Redirect } from 'react-router-dom';

const VerifyRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.user.isLogged ? (
        // user logged in
        <Component {...props} {...rest} />
      ) : (
        // user not logged in
        <Redirect
          to={{
            pathname: '/login',
            key: 'login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default VerifyRoute;
