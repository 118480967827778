import Rx from 'rxjs';

import {reducers} from './fe-core';
import UserReducers$ from './modules/user/reducers';

const reducer$ = Rx.Observable.merge(
  reducers,
  UserReducers$
);

export default reducer$;
