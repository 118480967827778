import {
  // FaPlusSquare,
  FaClone,
  FaPlus,
  FaMagic,
  FaCodeBranch,
  FaPlayCircle,
  FaStopCircle,
  FaFileMedical
} from 'react-icons/fa'

import { MdClose, MdDomain, MdFileUpload } from 'react-icons/md'

export {
  MdClose as DeleteIcon,
  FaPlus as AddIcon,
  FaClone as CloneIcon,
  FaPlus as VersioningIcon,
  FaMagic as PredictionIcon,
  MdDomain as ModelIcon,
  FaCodeBranch as ParentDerivedIcon,
  FaPlayCircle as Play,
  FaStopCircle as Stop,
  MdFileUpload as Upload,
  FaFileMedical as DatasetMultiOp
}

export default {
  DeleteIcon: MdClose,
  AddIcon: FaPlus,
  CloneIcon: FaClone,
  VersioningIcon: FaPlus,
  PredictionIcon: FaMagic,
  ModelIcon: MdDomain,
  ParentDerivedIcon: FaCodeBranch,
  Play: FaPlayCircle,
  Stop: FaStopCircle,
  Upload: MdFileUpload,
  DatasetMultiOp: FaFileMedical
}
