import Rx from 'rxjs';
import * as _ from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { lib } from '../../../fe-core';

import actions from '../actions/register';
import UserService from '../services/user.service';

const handleRemoteCall = lib.reducersUtil.handleRemoteCall;
const decode = lib.base64.decode;

const flatEvent = ev => ev.currentTarget.value;

const initialState = {
  showError: false,
  validated: false,
  validationError: {},
  isRegistered: false,
  username: '',
  password: '',
  passwordConfirm: '',
  loginError: false,
  currentUserError: false,
  resetParameters: {}
};

const validation = {
  username: {
    tests: [
      {
        validate: val => !!val && val.trim().length >= 3,
        msg: 'username must have at least 3 characters'
      },
      {
        validate: val => !!val && val.trim().length < 63,
        msg: 'username must be less than 63 characters'
      }
    ]
  },
  password: {
    tests: [
      {
        validate: val => !!val && val.trim().length >= 8,
        msg: 'password must have at least 8 characters'
      },
      {
        validate: val => !!val && val.trim().length < 128,
        msg: 'password must be less than 128 characters'
      }
    ]
  },
  passwordConfirm: {
    tests: [
      {
        validate: (val, others) => val === others.password,
        msg: "confirm password didn't match password"
      },
      {
        validate: (val, others) => val !== '',
        msg: 'confirm password cannot be empty'
      }
    ]
  },
  email: {
    tests: [
      {
        validate: val => !!val && isEmail(val),
        msg: 'email must be valid'
      }
    ]
  }
};

const validateForm = state => {
  let validationError = {};
  Object.keys(validation).forEach(k => {
    validation[k].tests.find(t => {
      const valid = t.validate(state[k], state);
      if (!valid) {
        const { msg, type = 'normal' } = t;
        validationError[k] = { msg, type };
      }
      return !valid;
    });
  });
  const validated = _.isEmpty(validationError);
  return {
    ...state,
    validationError,
    validated
  };
};

const RegisterReducer$ = Rx.Observable.of(() => initialState).merge(
  actions.registerUser.flatMap(userData => {
    return handleRemoteCall(
      UserService.registerUser(userData).map(({ err, data }) => state => ({
        ...state,
        isRegistered: err ? false : true,
        // clear password from state on successfully registerUser
        password: err ? userData.password : '',
        passwordConfirm: err ? userData.passwordConfirm : '',
        registerError: err
      }))
    );
  }),

  actions.verifyEmail.flatMap(queryParams => {
    return handleRemoteCall(
      UserService.verifyEmail(queryParams)
        //.delay(new Date(Date.now() + 50000)) // test api response delay
        .map(({ err, data }) => state => ({
          ...state,
          isConfirmed: data ? data.confirmed : false,
          confirmationError: err
          // isLogged: err ? false : true,
          // currentUserError: err,
          // username: data ? data.username : '',
          // email: data ? data.email : '',
        }))
    );
  }),

  actions.sendAgainConfirmEmail.flatMap(params => {
    return handleRemoteCall(
      UserService.sendAgainVerificationEmail(params)
        //.delay(new Date(Date.now() + 5000)) // test api response delay
        .map(({ err, data }) => state => ({
          ...state,
          emailSent: true
          // isLogged: err ? false : true,
          // currentUserError: err,
          // username: data ? data.username : '',
          // email: data ? data.email : '',
          // isConfirmed: data ? data.confirmed : false,
        }))
    );
  }),

  actions.sendResetPassword.flatMap(params => {
    return handleRemoteCall(
      UserService.sendResetPassword(params)
        //.delay(new Date(Date.now() + 5000)) // test api response delay
        .map(({ err, data }) => state => ({
          ...state,
          emailSent: err ? false : true,
          err
          // isLogged: err ? false : true,
          // currentUserError: err,
          // username: data ? data.username : '',
          // email: data ? data.email : '',
          // isConfirmed: data ? data.confirmed : false,
        }))
    );
  }),

  actions.verifyResetPassword.flatMap(params => {
    return handleRemoteCall(
      UserService.verifyResetPassword(params)
        //.delay(new Date(Date.now() + 5000)) // test api response delay
        .map(({ err, data }) => state => ({
          ...state,
          validCode: err ? false : true,
          err,
          resetPasswordParameters: params
          // isLogged: err ? false : true,
          // currentUserError: err,
          // username: data ? data.username : '',
          // email: data ? data.email : '',
          // isConfirmed: data ? data.confirmed : false,
        }))
    );
  }),

  actions.resetPassword.flatMap(params => {
    return handleRemoteCall(
      UserService.resetPassword(params)
        //.delay(new Date(Date.now() + 5000)) // test api response delay
        .map(({ err, data }) => state => ({
          ...state,
          passwordUpdated: err ? false : true,
          err
          // isLogged: err ? false : true,
          // currentUserError: err,
          // username: data ? data.username : '',
          // email: data ? data.email : '',
          // isConfirmed: data ? data.confirmed : false,
        }))
    );
  }),

  actions.setUsername
    .map(flatEvent)
    .map(username => state => validateForm({ ...state, username })),

  actions.setPassword
    .map(flatEvent)
    .map(password => state => validateForm({ ...state, password })),

  actions.setPasswordConfirm
    .map(flatEvent)
    .map(passwordConfirm => state =>
      validateForm({ ...state, passwordConfirm })
    ),

  actions.setEmail
    .map(flatEvent)
    .map(email => state => validateForm({ ...state, email })),

  actions.initializeValidation.map(() => state => {
    return validateForm({
      ...state,
      showError: false
    });
  }),

  actions.setShowError.map(showError => state => ({ ...state, showError })),

  actions.clearState.map(() => state => ({ ...state, ...initialState })),

  actions.saveInviteParameters.map(({ k: token, m: email }) => state => ({
    ...state,
    invite_token: decode(token),
    email: decode(email)
  })),

  actions.registerUserByInvitation.flatMap(userData => {
    return handleRemoteCall(
      UserService.registerUserByInvitation(userData).map(
        ({ err, data }) => state => ({
          ...state,
          isRegistered: err ? false : true,
          // clear password from state on successfully registerUser
          password: err ? userData.password : '',
          passwordConfirm: err ? userData.passwordConfirm : '',
          registerError: err
        })
      )
    );
  })
);

export default RegisterReducer$;
