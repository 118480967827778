import Rx from 'rxjs'

const loading = Rx.Observable.of({}).map(() => state => {
  if (state && state.constructor === Array) {
    return [...state]
  }

  return {
    ...state,
    loading: true
  }
})
const unloading = Rx.Observable.of({}).map(() => state => {
  if (state && state.constructor === Array) {
    return [...state]
  }
  return {
    ...state,
    loading: false
  }
})

const getErrorHandledObservable = observable => {
  const getErrorHandlerFor = err => {
    return Rx.Observable.of({}).map(() => _state => {
      throw err
    })
  }

  return observable.catch(err => {
    return getErrorHandlerFor(err).concat(unloading)
  })
}

export function handleRemoteCall(observable, { handleLoading = true } = {}) {
  if (!handleLoading) return getErrorHandledObservable(observable)

  return Rx.Observable.concat(
    loading,
    getErrorHandledObservable(observable),
    unloading
  )
}

export default {
  handleRemoteCall
}
