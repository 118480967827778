import Rx from 'rxjs';
import * as _ from 'lodash';
import { lib } from '../../../fe-core';
import * as Sentry from '@sentry/browser';

import actions from '../actions/user';
import UserService from '../services/user.service';
import config from '../../../configs';
const handleRemoteCall = lib.reducersUtil.handleRemoteCall;

const flatEvent = ev => {
  return ev.currentTarget.value;
};

const initialState = {
  showError: false,
  validated: false,
  validationError: {},
  isLogged: null,
  isConfirmed: null,
  username: '',
  password: '',
  loginError: false,
  currentUserError: false,
  messagesIds: []
};

const validation = {
  username: {
    tests: [
      {
        validate: username => !!username && username.trim().length > 0,
        msg: 'Username must be a valid string',
        type: 'critical'
      }
    ]
  },
  password: {
    tests: [
      {
        validate: password => !!password && password.trim().length > 0,
        msg: 'password must be a valid string',
        type: 'critical'
      }
    ]
  }
};

const validateForm = state => {
  let validationError = {};
  Object.keys(validation).forEach(k => {
    validation[k].tests.find(t => {
      const valid = t.validate(state[k], state);
      if (!valid) {
        const { msg, type = 'normal' } = t;
        validationError[k] = { msg, type };
      }
      return !valid;
    });
  });
  const validated = _.isEmpty(validationError);
  return {
    ...state,
    validationError,
    validated
  };
};

/*
confirmed:false
created_at:"2018-01-29T08:15:18.041Z"
email:"mi@mi.com"
id:"6acd60813d5d4ef0a48499c78dada193"
locale:null
password:"********************"
role:"READER"
updated_at:"2018-01-29T08:15:18.041Z"
username:"mirco"
version:0
*/
const handleGetCurrentUser = ({ err, data }) => state => {
  if (!err) {
    if (config.environment !== 'dev') {
      Sentry.configureScope(scope => {
        scope.setUser({
          email: data.email,
          id: data.id,
          username: data.username,
          services: data.services
        });
      });
    }
  }

  return {
    ...state,
    isLogged: err ? false : true,
    currentUserError: err,
    username: data ? data.username : '',
    email: data ? data.email : '',
    isConfirmed: data ? data.confirmed : false,
    services: data? data.services : []
  };
};

const UserReducer$ = Rx.Observable.of(() => initialState).merge(
  actions.loadUserData.flatMap(() => {
    return handleRemoteCall(
      UserService.getCurrentUser()
        //.delay(new Date(Date.now() + 5000)) // test api response delay
        .map(handleGetCurrentUser)
    );
  }),
  actions.login.flatMap(({ username, password }) => {
    return handleRemoteCall(
      UserService.login(username, password).map(({ err, data }) => state => {
        if (!err) {
          if (config.environment !== 'dev') {
            Sentry.configureScope(scope => {
              scope.setUser({
                email: data.email,
                id: data.id,
                username: data.username
              });
            });
          }
        }

        return {
          ...state,
          isLogged: err ? false : true,
          // clear password from state on successfully login
          password: err ? password : '',
          loginError: err,
          afterRegisterAlreadyShown: true,
          isConfirmed: data ? data.confirmed : false,
          username: data ? data.username : '',
          email: data ? data.email : '',
          services: data? data.services : []
        };
      })
    );
  }),

  actions.logout.flatMap(() => {
    return handleRemoteCall(
      UserService.logout()
        .map(({ err, data }) => state => {
          return {
            ...initialState,
            isLogged: false,
            err
          };
        })
        .catch(err =>
          Rx.Observable.of({}).map(() => _state => ({
            ...initialState,
            isLogged: false
          }))
        )
    );
  }),

  actions.setUsername
    .map(flatEvent)
    .map(username => state => validateForm({ ...state, username })),

  actions.setPassword
    .map(flatEvent)
    .map(password => state => validateForm({ ...state, password })),

  actions.initializeValidation.map(() => state => {
    return validateForm({
      ...state,
      showError: false
    });
  }),

  actions.setShowError.map(showError => state => ({ ...state, showError })),

  actions.clearError.map(() => state => ({
    ...state,
    username: '',
    password: '',
    loginError: null
  })),

  actions.addMessageToShow.map(msgId => state => {
    const currentMessagseIds = state.messagesIds;
    return { ...state, messagesIds: [...currentMessagseIds, msgId] };
  }),

  actions.removeMessageShowed.flatMap(({ msgId, timeout = 2000 }) => {
    return Rx.Observable.timer(timeout).map(() => state => {
      return {
        ...state,
        messagesIds: state.messagesIds.filter(e => e !== msgId)
      };
    });
  })
);

export default UserReducer$;
