import * as qs from 'qs';
import { state, hoc } from '../../../fe-core';
import { compose } from 'recompose';

import userActions from '../actions/user';
import RecoveryDoForm from '../components/RecoveryDoForm';
import registerActions from '../actions/register';

const { withStartAction }  = hoc;

const mapper = state => ({
  register: state.register
});

export default compose(
  state.RxState.connect(mapper, {
    ...registerActions,
    addMessageToShowLogin: userActions.addMessageToShow
  }),
  withStartAction(({ location = {}, verifyResetPassword }) => {
    const parsedQueryParams = qs.parse(location.search || '', {
      ignoreQueryPrefix: true
    });
    verifyResetPassword(parsedQueryParams);
  })
)(RecoveryDoForm);
