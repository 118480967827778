import { state, hoc } from '../../../fe-core';
import { compose } from 'recompose';

import registerActions from '../actions/register';
import RecoveryForm from '../components/RecoveryForm';

const { withStartAction } = hoc;

const mapper = state => ({
  register: state.register
});

export default compose(
  state.RxState.connect(mapper, {
    ...registerActions
  }),
  withStartAction(({ initializeValidation }) => {
    initializeValidation();
  })
)(RecoveryForm);
