import React from 'react';
import { Redirect } from 'react-router-dom';
import { hoc } from '../../../fe-core';

const VerifyEmail = props => {
  const {
    location = {},
    user: { isLogged = false } = {},
    register: { isConfirmed = false, confirmationError = false } = {}
  } = props;

  if (!isLogged) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          key: 'login',
          state: { from: location }
        }}
      />
    );
  }
  if (isConfirmed) {
    return <Redirect to="/" />;
  }

  if (confirmationError) {
    return <Redirect to="/verifyemailerror" />;
  }

  return null;
};

export default hoc.withLoading(props => props.register.loading)(VerifyEmail);
