import withLoading from './withLoading'
import withErrorBoundary from './withErrorBoundary'
import withModalErr from './withModalErr'
import withObservableFunc from './withObservableFunc'
import withPropsChecker from './withPropsChecker'
import withPropsEnforcements from './withPropsEnforcements'
import withStartAction from './withStartAction'
import withTooltip from './withTooltip'
import withValidation from './withValidation'

export default {
  withLoading,
  withErrorBoundary,
  withModalErr,
  withObservableFunc,
  withPropsChecker,
  withPropsEnforcements,
  withStartAction,
  withTooltip,
  withValidation
}
