import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Rx from 'rxjs'
import equal from 'fast-deep-equal'

import { omit, transform, isEqual, isObject } from '../lib/lodash'

export function createAction() {
  return new Rx.Subject()
}

export function createActions(actionNames) {
  return actionNames.reduce(
    (akk, name) => ({ ...akk, [name]: createAction() }),
    {}
  )
}
let version = 1
export function createState(reducer$, initialState$ = Rx.Observable.of({})) {
  return initialState$
    .merge(reducer$)
    .scan((state, [scope, reducer]) => {
      try {
        const update = reducer(state[scope])
        return {
          ...state,
          [scope]: update,
          __stateVersion: version++
        }
      } catch (err) {
        return {
          ...state,
          __stateVersion: version++,
          appError: err
        }
      }
    })
    .publishReplay(1)
    .refCount()
}

export function connect(selector = state => state, actionSubjects) {
  const actions = Object.keys(actionSubjects).reduce(
    (akk, key) => ({ ...akk, [key]: value => actionSubjects[key].next(value) }),
    {}
  )

  return function wrapWithConnect(WrappedComponent) {
    return class Connect extends Component {
      static contextTypes = {
        state$: PropTypes.object.isRequired
      }
      componentWillMount() {
        this.subscription = this.context.state$
          .map(data => ({
            ...selector(data),
            __stateVersion: data.__stateVersion
          }))
          .filter(
            data =>
              !this.state ||
              this.state.__stateVersion === undefined ||
              (data.__stateVersion > this.state.__stateVersion &&
                !equal(
                  omit(data, '__stateVersion'),
                  omit(this.state, '__stateVersion')
                ))
          )
          .subscribe(data => this.setState(data), err => console.log(err))
      }
      componentWillUnmount() {
        this.subscription.unsubscribe()
      }
      render() {
        return (
          <WrappedComponent {...this.state} {...this.props} {...actions} />
        )
      }
    }
  }
}

// --- state difference debugging ---
const difference = (object, base) => {
  const changes = (object, base) => {
    return transform(object, function(result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value
      }
    })
  }
  return changes(object, base)
}
// const expandedLog = (item, depth) => {
//   const MAX_DEPTH = 100
//   const expandedLogInner = (item, depth) => {
//     depth = depth || 0

//     if (depth > MAX_DEPTH) {
//       console.log(item)
//       return
//     }

//     if (_.isObject(item)) {
//       _.each(item, (value, key) => {
//         console.group(key + ' : ' + typeof value)
//         expandedLogInner(value, depth + 1)
//         console.groupEnd()
//       })
//     } else {
//       console.log(item)
//     }
//   }

//   return expandedLogInner(item, depth)
// }

let lastStateForDiff = {}
// ---

const LOG_ENABLED =
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === 'development' ||
  document.cookie.indexOf('debug=') >= 0 ||
  window.location.href.indexOf('debug=1') !== -1

export class Provider extends Component {
  // to log all state change
  componentWillMount() {
    if (LOG_ENABLED) {
      this.subscription = this.props.state$.subscribe(state => {
        const diff = difference(state, lastStateForDiff)
        console.log(
          'state',
          state,
          'state.' + state.__scopeUpdated,
          state[state.__scopeUpdated]
        )
        if (diff[state.__scopeUpdated]) {
          console.log(
            'state[' + state.__scopeUpdated + '] diff',
            diff[state.__scopeUpdated]
          )
          // expandedLog(diff[state.__scopeUpdated])
        } else {
          console.log('state diff', diff)
          // expandedLog(diff)
        }

        lastStateForDiff = JSON.parse(JSON.stringify(state))
      })
    }
  }
  componentWillUnmount() {
    if (LOG_ENABLED) this.subscription.unsubscribe()
  }

  // ------------------------
  static propTypes = {
    state$: PropTypes.object.isRequired
  }

  static childContextTypes = {
    state$: PropTypes.object.isRequired
  }

  getChildContext() {
    return { state$: this.props.state$ }
  }

  render() {
    return this.props.children
  }
}

Provider.propTypes = {
  children: PropTypes.node.isRequired
}

export default {
  createAction,
  createActions,
  createState,
  connect,
  Provider
}
