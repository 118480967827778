import React from 'react'
import { Observable } from 'rxjs'

import { componentFromStream, createEventHandler } from 'recompose'
/*
  methodMaps is an object array in the form
    method: pointer to service method
    alias: function name passed to component and field where response will be stored
 */
const withObservableFunc = (methodMaps = []) => WrappedComp => {
  return componentFromStream(props$ => {
    // create event handlers so a function that return an Observable can be called
    const eventHandlers = (methodMaps || []).map(el => {
      const { handler, stream } = createEventHandler()
      return { ...el, handler, stream }
    })
    // create a stream for each method
    const streams$ = eventHandlers.map(({ stream, method, alias }) =>
      stream
        .flatMap(
          /*
          this is a basic Observable managment for function implemented in service
          it add loading true at the beginning, call method passed with param form handler
        */

          param =>
            method(param)
              /**
               * event reduced and returned in an object identifiable by alias
               */
              .scan((acc, obj) => {
                return {
                  [alias]: { ...acc[alias], ...obj }
                }
              }, {})
        )
        .startWith({})
    )
    // merge all stream and return like a single object
    const results$ = Observable.merge(...streams$)
      // event reduced and returned
      .scan((acc, obj) => ({ ...acc, ...obj }), {})

    // associate alias to handler so it can be passed to Component
    const funcAlias = eventHandlers.reduce(
      (acc, el) => ({
        ...acc,
        [el.alias]: el.handler
      }),
      {}
    )

    return props$.combineLatest(results$, (props, res) => {
      return <WrappedComp {...props} res={{ ...res }} {...funcAlias} />
    })
  })
}
export default withObservableFunc
