import React from 'react'
import { Route } from 'react-router-dom'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const ButtonRedirect = props => {
  return (
    <Route
      render={({ history }) => (
        <Button
          style={{ whiteSpace: 'normal' }}
          {...props}
          onClick={e => {
            e && e.preventDefault()
            props.onClick && props.onClick()
            history.push(props.to)
          }}
        >
          {props.children}
        </Button>
      )}
    />
  )
}

ButtonRedirect.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  children: PropTypes.node
}

export default ButtonRedirect
