import React from 'react'
import { Container, Row, Col, Jumbotron } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './Home.css'
import './SelectApp.css'

const Home = props => {
  console.log(props)
  
  const { apps, user } = props;

  const selectors = apps.reduce((acc, app, index) => {
    if( (user.services || []).includes(app.service)) {
      const Selector = ({ history }) => {
        const {
          disabled = false,
          qai,
          home,
          description,
          redirectMode,
          icon: Icon,
          name
        } = app
        const classes = disabled
          ? classnames('app-row', 'disabled')
          : classnames('app-row')
        const onClick = disabled
          ? null
          : e => {
            e.preventDefault()
            if (redirectMode === 'push') {
              history.push(home)
            } else if (redirectMode === 'url') {
              // this force browser reload
              window.location = home
            }
          }
        return (
          <Row
            key={`app-${index}`}
            qai={qai}
            onClick={onClick}
            className={classes}
          >
            <Col sm='3' className='centered app-icon'>
              <Icon />
            </Col>
            <Col sm='9' className='app-info'>
              <h2>{name}</h2>
              <span>{description}</span>
            </Col>
            {disabled && (
              <div className='ribbon'>
                <span>coming soon</span>
              </div>
            )}
          </Row>
        )
      }
      acc.push(withRouter(Selector))
    } 
    return acc
  }, [])
  return (
    <Container fluid>
      <Row>
        <Col sm='12' md='12' lg={{ size: 6, offset: 3 }}>
          <Jumbotron qai='page-selectapp' fluid className='welcome'>
            <p className='lead'>
              Welcome to Insurengines, the platform for insurances
            </p>
            <hr className='my-2' />
            <p className='lead'>Choose what you want to do</p>
          </Jumbotron>
        </Col>
      </Row>
      <Row>
        <Col sm='12' md='12' lg={{ size: 6, offset: 3 }}>
          {selectors.map((Selector, i) => <Selector key={`sel-${i}`} />)}
        </Col>
      </Row>
    </Container>
  )
}

Home.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description:  PropTypes.string,
    home:  PropTypes.string,
    redirectMode:  PropTypes.oneOf(['url', 'push']),
    icon: PropTypes.node,
    qai:  PropTypes.string,
    disabled:  PropTypes.bool,
    service: PropTypes.string
  }))
}

export default Home