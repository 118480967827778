import React, { Component } from 'react';
import { MdHelpOutline as Icon } from 'react-icons/md';

export default class NoMatch extends Component {
  render() {
    return (
      <div className="error">
        <Icon color="white" size="70" />

        <p>
          Object not found for <code>{this.props.location.pathname}</code>
        </p>
      </div>
    );
  }
}
