import React from 'react'
import {
  Input,
  Label,
  FormGroup,
  FormFeedback,
  Col,
  Row,
  FormText
} from 'reactstrap'
import PropTypes from 'prop-types'
import { FaPlusCircle, FaMinusCircle, FaInfoCircle } from 'react-icons/fa'
import { compose, withState, withHandlers } from 'recompose'

import withTooltip from '../hoc/withTooltip'
import ButtonIcon from './ButtonIcon'
import { getFormatValue } from './ValidatedInput'

const InputRow = props => {
  const {
    fieldName,
    type,
    id,
    placeholder,
    getValueAt,
    setValueAt,
    getEmptyItem,
    isValidFieldAt,
    disabled = false,
    outputFormat = 'string',
    removeItemAt,
    index
  } = props

  return (
    <Row className='no-gutters p-1'>
      {/* <Col sm={1} className="my-auto mx-1">
        {index}.
      </Col> */}
      <Col sm={10}>
        <Input
          type={type}
          name={fieldName}
          id={id}
          onChange={e =>
            setValueAt(index, getFormatValue(outputFormat, e.target.value))
          }
          placeholder={placeholder}
          valid={isValidFieldAt(index)}
          invalid={!isValidFieldAt(index)}
          value={getValueAt(index) || getEmptyItem()}
          disabled={disabled}
          min={type === 'number' ? '0' : ''}
        />
      </Col>
      <Col sm={1} className='my-auto mx-1'>
        <ButtonIcon
          // small
          icon={FaMinusCircle}
          color='danger'
          text=''
          id={'removeIndex' + index}
          datatoggle={`Remove this item from array (index: ${index + 1})`}
          onClick={e => removeItemAt(index)}
        />
      </Col>
    </Row>
  )
}

const Info = withTooltip(props => <FaInfoCircle size={32} {...props} />)

const ValidatedInputArray = props => {
  const {
    fieldName,
    label,
    id,
    getErrorMsg,
    row = false,
    labelCss = { sm: 12 },
    colCss = { sm: 9 },
    infoCss = { sm: 12 },
    getValuesArray,
    addEmptyItem,
    informations = '',
    infoOpened,
    openInfo
  } = props

  const valuesArray = getValuesArray()

  return (
    <FormGroup row={row}>
      <Label sm={3} {...labelCss}>
        {label}
      </Label>
      <Col sm={9} {...colCss}>
        {/* existing values */}
        {valuesArray.map(
          (value, index) => (
            <InputRow
              key={'rowinput' + index}
              value={value}
              index={index}
              {...props}
            />
          )
          // <b> ok </b>
        )}
        <Row
          className={
            'pb-3' + (valuesArray && valuesArray.length ? ' pt-1' : '')
          }
        >
          <Col sm={12}>
            <ButtonIcon
              icon={FaPlusCircle}
              color='info'
              text='Add'
              id='addMetric'
              datatoggle='Add a new item'
              onClick={addEmptyItem}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <FormFeedback>{getErrorMsg(fieldName)}</FormFeedback>
          </Col>
        </Row>
      </Col>
      {informations && (
        <Col {...infoCss}>
          <Info
            showDelayMs={'100'}
            id={'info-' + id}
            datatoggle={informations}
            className='info-toggler'
            onClick={e => openInfo(!infoOpened)}
          />
        </Col>
      )}
      {infoOpened && (
        <Col sm={12} className='mb-2 text-center'>
          <FormText>{informations}</FormText>
        </Col>
      )}
    </FormGroup>
  )
}

ValidatedInputArray.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelCss: PropTypes.object,
  colCss: PropTypes.object,
  outputFormat: PropTypes.oneOf(['int', 'float', 'string'])

  /*
    This elements are passed via ValidForm extending children.
    React doesn't recognize as set...but they are, so disable this check

    setValue: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    isValidField: PropTypes.func.isRequired,
    isInvalidField: PropTypes.func.isRequired,
    getErrorMsg: PropTypes.func.isRequired,
  */
}

const enhance = compose(
  withHandlers({
    getValuesArray: props => () => {
      const value = props.getValue(props.fieldName)
      if (!value || value.constructor !== Array) {
        return []
      }
      return value
    },
    getEmptyItem: props => () => {
      return ''
      // const emptiesByType = {
      //   int: 0,
      //   float: 0.0,
      //   string: ''
      // };
      // return emptiesByType[props.outputFormat];
    },
    isValidValue: props => value => {
      // if (props.outputFormat === 'int') {
      //   return true;
      // }
      // if (props.outputFormat === 'float') {
      //   return true;
      // }
      // if (props.outputFormat === 'string') {
      //   return true;
      // }
      return true
    }
  }),
  withHandlers({
    removeItemAt: props => index => {
      const currentValue = props.getValuesArray()
      let newValue = currentValue.slice() // copy
      newValue.splice(index, 1) // change the copy
      //
      if (newValue.length === 0) {
        newValue = null
      }
      props.setValue(props.fieldName, newValue)
    },
    getValueAt: props => index => {
      const currentValue = props.getValuesArray()
      return currentValue[index]
    },
    isValidFieldAt: props => index => {
      const currentValue = props.getValuesArray()
      return props.isValidValue(currentValue[index])
    },
    setValueAt: props => (index, value) => {
      const currentValue = props.getValuesArray()
      currentValue[index] = value
      props.setValue(props.fieldName, currentValue)
    },
    addEmptyItem: props => () => {
      const currentValue = props.getValuesArray()
      currentValue.push(props.getEmptyItem())
      props.setValue(props.fieldName, currentValue)
    }
  }),
  withState('infoOpened', 'openInfo', false)
)

export default enhance(ValidatedInputArray)
