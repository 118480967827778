import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { onlyUpdateForKeys, withState, compose } from 'recompose'
import PropTypes from 'prop-types'

const withModalErr = (getErr, resetErr) => WrappedComp => {
  return props => {
    const err = getErr(props)
    if (!err) return <WrappedComp {...props} />
    // else
    // get error reset function from props
    const resetFunc = resetErr(props)
    const ModalErr = ({ open, toggleOpen }) => {
      const closeModal = e => {
        // call reset error function and close modal
        if (resetFunc) resetFunc()
        toggleOpen(!open)
      }
      return (
        <Modal isOpen={open}>
          <ModalHeader>Error</ModalHeader>
          <ModalBody style={{}}>
            <span style={{ display: 'block' }}>An error occured:</span>
            <span style={{ display: 'block' }}>{err}</span>
            <span style={{ display: 'block' }}>Retry later</span>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={closeModal}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      )
    }
    ModalErr.propTypes = {
      toggleOpen: PropTypes.func,
      open: PropTypes.bool
    }
    const ModalErrEnhanced = compose(
      onlyUpdateForKeys(['err']),
      withState('open', 'toggleOpen', true)
    )(ModalErr)

    return (
      <React.Fragment>
        <WrappedComp {...props} />
        <ModalErrEnhanced err={err} />
      </React.Fragment>
    )
  }
}

export default withModalErr
