import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  InputGroup,
  Input,
  FormFeedback,
  Alert
} from 'reactstrap';
import PropTypes from 'prop-types';
import { MdPermIdentity, MdLock } from 'react-icons/md';
import { components } from '../../../fe-core';

import LoginStylePage from './LoginStylePage';
import { REGISTEROK } from '../reducers/constants';

const { HRWithText, ButtonRedirect, InputRowWithIcon } = components;

const MyInputRow = props => {
  const {
    RowIcon,
    placeholder,
    name,
    type = 'text',
    value,
    onChange,
    validFunc = () => true,
    errorFeedback = ''
  } = props;
  return (
    <FormGroup className="mt-4">
      <InputGroup>
        <div className="input-group-prepend">
          <span className="input-group-text half-transparent-input">
            <RowIcon size={24} />
          </span>
        </div>
        <Input
          className="half-transparent-input"
          type={type}
          placeholder={placeholder}
          name={name}
          id={name}
          onChange={onChange}
          value={value}
          valid={validFunc()}
        />
        <FormFeedback>{errorFeedback}</FormFeedback>
      </InputGroup>
    </FormGroup>
  );
};
MyInputRow.propTypes = {
  RowIcon: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email']),
  value: PropTypes.string,
  onChange: PropTypes.func,
  validFunc: PropTypes.func,
  errorFeedback: PropTypes.string
};

const MyRegisterForm = props => {
  const {
    registerUserByInvitation,
    setUsername,
    setPassword,
    setPasswordConfirm,
    register: {
      username = '',
      password = '',
      passwordConfirm = '',
      email = '',
      validationError = {}, // client side validation error
      validated, // client side validation error
      showError,
      invite_token
    } = {},
    setShowError
  } = props;

  const validField = err => {
    const error = err ? err.msg : false;
    if (error && err && err.type === 'critical') return false;
    return showError ? !error : undefined;
  };
  const getErrorMsg = err => (err ? err.msg : null);

  const formSubmit = ev => {
    ev.preventDefault();
    //const data = new FormData(ev.target);
    //const username = data.get('username');
    //const password = data.get('password');
    //login({ username, password });

    if (!validated) {
      setShowError(true);
    } else {
      registerUserByInvitation({
        username,
        password,
        passwordConfirm,
        email,
        invite_token
      });
    }
  };
  return (
    <Form onSubmit={formSubmit}>
      <InputRowWithIcon
        RowIcon={MdPermIdentity}
        placeholder="Username"
        name="username"
        value={username}
        onChange={setUsername}
        validFunc={() => validField(validationError.username)}
        errorFeedback={getErrorMsg(validationError.username)}
        showValidity={showError}
        showFeedback={showError}
      />

      <InputRowWithIcon
        RowIcon={MdLock}
        type="password"
        placeholder="Password"
        name="password"
        value={password}
        onChange={setPassword}
        validFunc={() => validField(validationError.password)}
        errorFeedback={getErrorMsg(validationError.password)}
        showValidity={showError}
        showFeedback={showError}
      />

      <InputRowWithIcon
        RowIcon={MdLock}
        type="password"
        placeholder="Confirm password"
        name="passwordConfirm"
        value={passwordConfirm}
        onChange={setPasswordConfirm}
        validFunc={() => validField(validationError.passwordConfirm)}
        errorFeedback={getErrorMsg(validationError.passwordConfirm)}
        showValidity={showError}
        showFeedback={showError}
      />

      <Button qai="s" className="float-left mt-2" color="primary">
        Register
      </Button>
    </Form>
  );
};

class RegisterByInviteForm extends Component {
  render() {
    const {
      clearState,
      setUsernameLogin,
      addMessageToShowLogin,

      register: {
        username,
        isRegistered,
        registerError // server side error on login
      } = {}
    } = this.props;

    // if is already logged or the login process
    //  is completed go to route from which we arrived to login
    if (isRegistered) {
      // clear register state to permit another register
      clearState();
      // save username on login state
      setUsernameLogin({ currentTarget: { value: username } });
      // save registration completed message on login form
      addMessageToShowLogin(REGISTEROK);

      return <Redirect to="/login" />;
    }

    return (
      <LoginStylePage qai="page-register">
        <h3>Register</h3>

        {registerError ? (
          <Alert className="mt-4" color="danger">
            Error:{' '}
            {registerError.msg
              ? registerError.msg
              : 'please check your request'}
          </Alert>
        ) : null}

        <MyRegisterForm className="p-3" {...this.props} />

        <HRWithText text="" />

        <ButtonRedirect to="/login" color="link">
          Already InsurEngines Member? Login Now!
        </ButtonRedirect>
      </LoginStylePage>
    );
  }
}

export default RegisterByInviteForm;
