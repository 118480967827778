import React from 'react'
import {
  Input,
  Label,
  FormGroup,
  FormFeedback,
  FormText,
  Col
} from 'reactstrap'
import PropTypes from 'prop-types'
import { FaInfoCircle } from 'react-icons/fa'
import { compose, withState, lifecycle } from 'recompose'

import withTooltip from '../hoc/withTooltip'

import './ValidatedInput.css'

export function getFormatValue(format, value) {
  switch (format) {
    // case 'string':
    //   return value;
    case 'int':
      return value ? parseInt(value, 10) : ''
    case 'float':
      return value ? value.match(/([0-9]*(\.[0-9]*)?)/)[0] : ''
    default:
      return value
  }
}

const ValidatedInput = props => {
  const {
    fieldName,
    label,
    type,
    id,
    placeholder,
    getValue,
    setValue,
    isValidField,
    isInvalidField,
    getErrorMsg,
    disabled = false,
    row = false,
    labelCss = { sm: 12 },
    colCss = { sm: 12 },
    infoCss = { sm: 12 },
    outputFormat,
    informations = '',
    infoOpened,
    openInfo,
    inline = false
  } = props

  const Info = withTooltip(props => <FaInfoCircle size={32} {...props} />)

  return (
    <React.Fragment>
      {type === 'checkbox' ? (
        <FormGroup check {...colCss}>
          <Label check>
            <Input
              type='checkbox'
              name={fieldName}
              id={id}
              onChange={e =>
                setValue(
                  fieldName,
                  getFormatValue(outputFormat, e.target.value)
                )
              }
              valid={isValidField(fieldName)}
              invalid={isInvalidField(fieldName)}
              value={getValue(fieldName) || ''}
              disabled={disabled}
            />{' '}
            {label}
          </Label>
        </FormGroup>
      ) : inline ? (
        <Col {...colCss}>
          <Input
            type={type}
            name={fieldName}
            id={id}
            onChange={e =>
              setValue(fieldName, getFormatValue(outputFormat, e.target.value))
            }
            placeholder={placeholder}
            valid={isValidField(fieldName)}
            invalid={isInvalidField(fieldName)}
            value={getValue(fieldName) || ''}
            disabled={disabled}
          >
            {props.children}
          </Input>
          <FormFeedback>{getErrorMsg(fieldName)}</FormFeedback>
        </Col>
      ) : (
        <FormGroup row={row}>
          <Label for={id} {...labelCss}>
            {label}
          </Label>
          <Col {...colCss}>
            <Input
              type={type}
              name={fieldName}
              id={id}
              onChange={e =>
                setValue(
                  fieldName,
                  getFormatValue(outputFormat, e.target.value)
                )
              }
              placeholder={placeholder}
              valid={isValidField(fieldName)}
              invalid={isInvalidField(fieldName)}
              value={getValue(fieldName) || ''}
              disabled={disabled}
              min={type === 'number' ? '0' : ''}
            >
              {props.children}
            </Input>
            <FormFeedback>{getErrorMsg(fieldName)}</FormFeedback>
          </Col>
          {informations && (
            <Col {...infoCss}>
              <Info
                showDelayMs={'100'}
                id={'info-' + id}
                datatoggle={informations}
                className='info-toggler'
                onClick={e => openInfo(!infoOpened)}
              />
            </Col>
          )}
          {infoOpened && (
            <Col sm={12} className='mb-2 text-center'>
              <FormText>{informations}</FormText>
            </Col>
          )}
        </FormGroup>
      )}
    </React.Fragment>
  )
}

ValidatedInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelCss: PropTypes.object,
  colCss: PropTypes.object,
  outputFormat: PropTypes.oneOf(['int', 'float', 'string'])

  /*
    This elements are passed via ValidForm extending children.
    React doesn't recognize as set...but they are, so disable this check

    setValue: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    isValidField: PropTypes.func.isRequired,
    isInvalidField: PropTypes.func.isRequired,
    getErrorMsg: PropTypes.func.isRequired,
  */
}
export default compose(
  withState('infoOpened', 'openInfo', false),
  lifecycle({
    componentDidMount() {
      const { getDefault, setValue, fieldName, outputFormat } = this.props
      const defaultValue = getDefault(fieldName)
      if (defaultValue) {
        setValue(fieldName, getFormatValue(outputFormat, defaultValue))
      }
    }
  })
)(ValidatedInput)
