import React from 'react'

import './IconOnly.css'

const IconOnly = props => {
  const {
    id,
    icon: Icon,
    onClick = e => false,
    className = ''
  } = props

  return (
    <span id={id} onClick={onClick} className={'icon-only ' + className}>
      <Icon size={36} />
    </span>
  )
}

export default IconOnly
