import { onlyUpdateForKeys, compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import { connect } from '../state/RxState'
// import actions from '../../user/actions/user'
import SinkOnError from '../components/SinkOnError'

const mapper = ({ appError }) => ({
  appError
})

export default (actions) => compose(
  connect(mapper, { ...actions }),
  onlyUpdateForKeys(['appError']),
  withRouter
)(SinkOnError)
