export default {
  REGISTER: '/register',
  REGISTERINVITE: '/acceptinvite',
  REGISTEROK: '/registersuccess',
  REGISTERFORGETPW: '/recovery',
  REGISTERFORGETPWDO: '/recoverydo',
  REGISTERCHECKEM: '/verifyemail',
  REGISTERCONFIRM: '/confirm',
  REGISTERCHECKEMERR: '/verifyemailerror',
  ROOTSELECTAPP: '/',

  HOMEANALYSIS: '/analysis',
  HOMEESTIMATOR: '/estimator',
  HOMEADMIN: '/admin',
  
  NOMATCHCATCHALL: '/',

  HOMETARIF: '/tariff'
};
