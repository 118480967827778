import Rx from 'rxjs'

import { isString } from '../lib/lodash'
import {
  getErrorMessage,
  AuthorizationError,
  ApiError,
  ClientError
} from '../lib/errorUtil'

export default class RestService {
  cacheMap = {}
  apiUrl = '/api/analysis'

  /**
   * toDatatableQuery convert a react-table request to a server pagination request,
   *   can be used also for advanced search using the "where" option
   *
   * Examples of where condition parameter:
   *  1. condition on name field with operator specification:
   *    {
   *      name: {op: 'eq', value: filename}
   *    }
   *
   *
   * @param {Object} request - parameters for the pagination request
   * @param {Array} request.columns - list of field needed by datatable client
   * @param {Array} request.sorted - list of field used for sorting, only the first is considered in v1 of the advanced pagination interface
   * @param {Array} request.filtered - list of fields with search term for each field, used when searching in a specific field with react-table
   * @param {Number} request.page - page requested, starting from 1
   * @param {Number} request.pageSize - number of item returned in each page
   * @param {string} request.search - string to search on all fields
   * @param {Object} request.where - where condition, inside you can use any operator handled by Sequelize on the server
   *
   */
  toDatatableQuery(request) {
    const {
      // columns = [],
      pageSize: length = 10,
      page = 1,
      sorted = [],
      filtered = [],
      search = '',
      where
    } = request
    // console.log("[RestService] toDatatableQuery() ", request)

    const params = {
      limit: length,
      offset: Math.max(length * page, 0),
      search,
      orderfield: '',
      orderdir: '',
      where
    }
    if (sorted && sorted.length > 0) {
      const firstCol = sorted[0]
      params.orderfield = firstCol.id
      params.orderdir = firstCol.desc ? 'desc' : 'asc'
    }
    if (filtered && filtered.length > 0) {
      params.where = filtered.reduce((acc, curr) => {
        // id: "anno", value: "1"
        const { id = '', value = '' } = curr
        acc[id] = value
        // acc[id] = {op: 'iLike', value}
        return acc
      }, {})
    }

    return params
  }

  extractData(params, inData) {
    const { data = [], meta: { total = 0, filtered = 0 } = {} } = inData
    const recTot = isString(total) ? parseInt(total, 10) : total
    const recFilt = isString(filtered) ? parseInt(filtered, 10) : filtered
    const { pageSize = 1, search, filtered: requestedFilter = [] } = params
    let totalFoundForPagination = recTot
    if (search || requestedFilter.length > 0) {
      totalFoundForPagination = recFilt
    }

    const pages =
      totalFoundForPagination === 0
        ? 0
        : Math.ceil(totalFoundForPagination / pageSize)
    return {
      data,
      pages,
      recordsTotal: recTot,
      recordsFiltered: recFilt,
      request: params
    }
  }

  handleDtQueryResponse(params) {
    return ({ err, data: inData }) => {
      const data = inData ? this.extractData(params, inData) : {}
      return { err, data }
    }
  }

  post(url, body, options) {
    return Rx.Observable.ajax
      .post(url, body, { 'Content-Type': 'application/json', ...options })
      .catch(err => {
        console.error('[RestService] POST error: ', err)
        return Rx.Observable.of(err.xhr)
      })
      .map(e => this.handleResponse(e))
  }

  put(url, body, options) {
    return Rx.Observable.ajax
      .put(url, body, { 'Content-Type': 'application/json', ...options })
      .catch(err => {
        console.error('[RestService] PUT error: ', err)
        return Rx.Observable.of(err.xhr)
      })
      .map(e => this.handleResponse(e))
  }

  delete(url) {
    return Rx.Observable.ajax
      .delete(url)
      .catch(err => {
        console.error('[RestService] DELETE error: ', err)
        return Rx.Observable.of(err.xhr)
      })
      .map(e => this.handleResponse(e))
  }

  get(url) {
    return Rx.Observable.ajax(url)
      .catch(err => {
        console.error('[RestService] GET error: ', err)
        return Rx.Observable.of(err.xhr)
      })
      .map(e => this.handleResponse(e))
  }
  handleResponseError(e, err) {
    if (e.status) {
      const status = parseInt(e.status, 10)
      if (status === 401) {
        throw new AuthorizationError(err, status, 'Unauthorized user')
      }

      if (status >= 500) {
        throw new ApiError(err, status, 'Api error')
      }

      if (status >= 400) {
        throw new ClientError(err, status, 'Client error')
      }
    }
  }
  handleResponse(e) {
    // console.log('[RestService] handleResponse(): ', e)
    const res = e.response
    let data = res && res.status === 'success' ? res.data : null
    let err =
      res && res.status === 'fail'
        ? res.error ? res.error : { msg: 'unknown error' }
        : !res ? { msg: 'unknown error' } : null

    // if there is an HTTP error and the response isn't
    //  properly set: consider the response as an error
    if (e.status && !(e.status >= 200 && e.status < 300) && !err) {
      err = {
        msg: (res && res.message) ? res.message : res || 'unknown error'
      }
      data = null
    }

    // centralized error formatting and translation
    if (err && err.msg) {
      err.msg = getErrorMessage(err)
    }

    this.handleResponseError(e, err)

    return { err, data }
  }
  cache(stream$) {
    return stream$.map(res => {
      if (res.data) {
        this.updateCache(res.data ? [res.data] : [])
      }
      return res
    })
  }

  updateCache(items) {
    (items || []).forEach(
      it => (this.cacheMap[it.id] = { err: null, data: it })
    )
  }

  getCached(id) {
    return this.cacheMap[id]
  }
  getAllCached() {
    return Object.values(this.cacheMap)
  }
  resetCache() {
    this.cacheMap = {}
  }
}
