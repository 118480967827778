import React from 'react'
import { Form } from 'reactstrap'
import withValidation from '../hoc/withValidation'
import PropTypes from 'prop-types'

function filterValidateElement(props) {
  const {
    setValue,
    getValue,
    getDefault,
    isValidField,
    isInvalidField,
    getErrorMsg,
    submitForm,
    onSubmit,
    resetForm,
    submitted,
    changed,
    disableAfterSubmit = false,
    loading = false,
    err = false,
    child
  } = props
  const forceDisable = (submitted && disableAfterSubmit && !err) || loading
  return child && typeof child === 'object'
    ? child.props &&
      ((child.props['fieldName'] && child.props['type']) ||
        child.props['validated'])
      ? React.cloneElement(child, {
        setValue,
        isValidField,
        isInvalidField,
        getErrorMsg,
        getValue,
        getDefault,
        submitForm,
        onSubmit,
        resetForm,
        disabled: forceDisable ? true : child.props.disabled
      })
      : forceDisable || (child.props.type === 'submit' && !changed)
        ? React.cloneElement(child, { disabled: true })
        : child
    : child
}

export function FilterValidatedChildren(props) {
  const { children } = props

  const childrenWithProps = React.Children.map(children, child =>
    filterValidateElement({ ...props, child })
  )
  return childrenWithProps
}

const ValidatedForm = props => {
  const { onSubmit, submitForm, resetForm } = props

  const childrenWithProps = FilterValidatedChildren(props)
  return (
    <Form onSubmit={e => submitForm(e, onSubmit)} onReset={e => resetForm()}>
      {childrenWithProps}
    </Form>
  )
}
ValidatedForm.propTypes = {
  validator: PropTypes.object.isRequired,
  formatter: PropTypes.func,
  /* not required when is a new element */
  element: PropTypes.object,
  defaults: PropTypes.object,
  submitForm: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  isValidField: PropTypes.func.isRequired,
  getErrorMsg: PropTypes.func.isRequired
}

export default withValidation(ValidatedForm)
