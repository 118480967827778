
/**
 *
 * Example error object
 * {
 *     "msg": "Invalid model parameter: hidden",
 *     "code": "INVALID_MODEL_PAR",
 *     "args": {
 *         "PARAMETER_NAME": "hidden"
 *     }
 * }
 *
 * @param {Object} errorObject error object to be parsed
 * @param {String} errorObject.msg full error description with parameter already compiled in
 * @param {Object} errorObject.args error message parameters
 * @param {String} errorObject.code short uppercased string describing the error
 *
 */
export function getErrorMessage({ msg, args, code, desc, noDefault = false }) {
  const defaultMsg = 'Unknown error'
  const result = msg || desc

  if (noDefault) {
    return result
  }

  return result || defaultMsg
}

//
// custom error builder, adapted from https://github.com/jproulx/node-custom-error
//
function createError(name, parameters) {
  if (!name) {
    throw new TypeError('A custom error name is required')
  }

  // If we're given additional parameters, attach them to the created object
  var properties = {}
  if (parameters) {
    Object.keys(parameters).forEach(function(property) {
      properties[property] = {
        value: parameters[property],
        enumerable: true,
        writable: true,
        configurable: true
      }
    })
  }

  // The custom error function that's returned. Since it always creates a new
  // exception, we don't have to worry if itself was invoked with a new
  // operator or not.
  function CustomError(info = {}, httpCode = 500, message) {
    // We start by simply creating a new error object, so that we preserve
    // the runtime error logic
    var proxy = new Error(message)
    // We want to call our constructor on the error object itself,
    Error.apply(this, arguments)
    // We also want to preserve the inheritance logic in the prototype chain
    Error.apply(proxy, arguments)

    // Capture the stack trace at the appropriate stack location
    Error.captureStackTrace(proxy, CustomError)

    properties.info = { value: info }
    properties.httpCode = { value: httpCode }

    // Pass in our extra properties
    Object.defineProperties(proxy, properties)

    // Replace the error prototype with our own
    // FIXME
    // eslint-disable-next-line no-proto
    proxy.__proto__ = Object.create(CustomError.prototype, properties)
    return proxy
  }

  // Set up the new prototype
  var proto = {
    constructor: {
      value: CustomError,
      writable: true,
      configurable: true
    },
    name: {
      value: name,
      enumerable: false,
      writable: true,
      configurable: true
    }
  }
  // Also set the passed in parameters on the prototype if it's inherited later
  if (parameters) {
    Object.keys(parameters).forEach(function(name) {
      proto[name] = {
        value: parameters[name],
        enumerable: true,
        writable: true,
        configurable: true
      }
    })
  }
  // Copying from the Error prototype allows us to preserve the built-in error checks
  CustomError.prototype = Object.create(Error.prototype, proto)
  return CustomError
}

export const AuthorizationError = createError('AuthorizationError')

export const ApiError = createError('ApiError')

export const ClientError = createError('ClientError')

export default {
  getErrorMessage,
  AuthorizationError,
  ApiError,
  ClientError
}
