import React from 'react';

const VerifyEmailError = () => {
  return (
    <div className="m-2 p-3">
      <h1>Email validation error</h1>

      <p className="p-3">Validation link expired or invalid</p>
    </div>
  );
};

export default VerifyEmailError;
