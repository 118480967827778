import React from 'react';

import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.user.isLogged ? (
        rest.user.isConfirmed ? (
          // user loggedin and confirmed
          <Component {...props} {...rest} />
        ) : (
          // user logged in, but not confirmed
          <Redirect
            to={{
              pathname: '/confirm',
              key: 'confirm',
              state: { from: props.location }
            }}
          />
        )
      ) : (
        // user not logged in
        <Redirect
          to={{
            pathname: '/login',
            key: 'login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
