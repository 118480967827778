import { state, hoc } from '../../../fe-core';
import { compose } from 'recompose';

import RegisterForm from '../components/RegisterForm';
import registerActions from '../actions/register';
import userActions from '../actions/user';

const { withStartAction } = hoc;

const mapper = state => ({
  register: state.register
});

export default compose(
  state.RxState.connect(mapper, {
    ...registerActions,
    setUsernameLogin: userActions.setUsername,
    addMessageToShowLogin: userActions.addMessageToShow
  }),
  withStartAction(({ initializeValidation }) => initializeValidation())
)(RegisterForm);
