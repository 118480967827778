import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
} from 'reactstrap';
import { hoc } from '../../../fe-core';

import LoginStylePage from './LoginStylePage';

const { withLoading } = hoc;

const Confirm = props => {
  const { sendAgainConfirmEmail, register: { emailSent = false } = {} } = props;

  if (emailSent) {
    return <Redirect to="/registersuccess" />;
  }

  const resend = () => {
    sendAgainConfirmEmail();
  };

  return (
    <LoginStylePage qai="page-confirm">
      <h3>Email confirmation needed</h3>

      <p className="p-3">
        Your email is not confirmed, check your inbox for an email from us and
        click on the link to confirm it.
      </p>

      <p className="p-3">
        If you didn't receive the email, check the spam folder or
        <Button className="m-2" color="primary" onClick={resend}>
          send the confirm again
        </Button>
      </p>
    </LoginStylePage>
  );
};

export default withLoading(props => props.register.loading)(Confirm);
