import Rx from 'rxjs'
import actions from '../actions/queryRemoteActions'
import QueryService from '../service/query.service'
import { handleRemoteCall } from '../lib/reducersUtil'

const initialState = { queryRes: {} }

const QueryRemoteReducer$ = Rx.Observable.of(() => initialState).merge(
  actions.queryRemote.flatMap(({ url, ...params }) => {
    return handleRemoteCall(
      QueryService.queryRemote({ url, ...params }).map(res => state => ({
        ...state,
        queryRes: res
      }))
    )
  }),
  actions.resetQueryRemote.map(() => state => initialState)
)

export default QueryRemoteReducer$
