import React from 'react'

const withStartAction = actionCaller => WrappedComponent => {
  return class extends React.Component {
    componentDidMount() {
      actionCaller(this.props)
    }
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}
export default withStartAction
