import React from 'react';
import isEmail from 'validator/lib/isEmail';
import { Button, Alert } from 'reactstrap';
import { components } from '../../../fe-core';

import LoginStylePage from './LoginStylePage';
import config from '../../../configs';

const { HRWithText, ButtonRedirect, ValidatedInput, ValidatedForm } = components;

const createValidator = () => ({
  email: [
    {
      validate: val => !!val && isEmail(val),
      msg: 'email must be valid'
    }
  ]
});

const RecoveryForm = props => {
  const {
    register: { err, loading, emailSent } = {},
    sendResetPassword
  } = props;

  const onSubmit = el => {
    sendResetPassword({ ...el });
  };

  return (
    <LoginStylePage qai="page-recovery">
      <h3>Forgot your password?</h3>

      {err ? (
        <Alert className="m-3 p-3" color="danger">
          An error occured: {err.msg}
        </Alert>
      ) : emailSent ? (
        <Alert className="m-3 p-3" color="info">
          We sent you an email with instructions on how to reset your password.
        </Alert>
      ) : null}

      {!emailSent && (
        <ValidatedForm onSubmit={onSubmit} validator={createValidator()}>
          <ValidatedInput
            fieldName="email"
            label="Just let us know what email address you used to register and we'll send
          you an email with instructions."
            type="text"
            id="email"
            placeholder={'Email'}
          />
          <Button color="primary" type="submit" disabled={loading}>
            send via email
          </Button>{' '}
          {config.registerUserOpen && <HRWithText text="" />}
          {config.registerUserOpen && (
            <ButtonRedirect to="/register" qai="r" color="link">
              New to InsurEngines? Register Now!
            </ButtonRedirect>
          )}
          <HRWithText text="" />
          <ButtonRedirect to="/login" qai="l" color="link">
            Already InsurEngines Member? Login Now!
          </ButtonRedirect>
        </ValidatedForm>
      )}
    </LoginStylePage>
  );
};

export default RecoveryForm;
