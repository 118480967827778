import React from 'react';

import LoginStylePage from './LoginStylePage';

const RegisterSuccess = () => {
  return (
    <LoginStylePage qai="page-register-confirm">
      <h3>Register</h3>

      <p className="p-4">You almost finished...</p>

      <p className="p-4">
        ...to activate your account you must confirm your email.
      </p>

      <p className="p-4">
        Please click on the link inside the email we just sent you.
      </p>
    </LoginStylePage>
  );
};

export default RegisterSuccess;
