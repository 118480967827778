import { state } from '../../../fe-core';

export default state.RxState.createActions([
  'loadUserData',
  'login',
  'logout',
  'setUsername',
  'setPassword',
  'setShowError',
  'initializeValidation',
  'clearError',
  'addMessageToShow',
  'removeMessageShowed'
]);
