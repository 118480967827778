import React from 'react'
import { MdBubbleChart as BubbleChart, MdAttachMoney } from 'react-icons/md'
import { FaUserLock, FaFileInvoiceDollar } from 'react-icons/fa'

import SelectApp from '../../fe-core/components/SelectApp'

import RoutesPathConfig from '../../configs/routes'

export default (props) => (<SelectApp apps={[
  {
    name: 'Analysis',
    service: "analysis",
    description: 'Analyze your data to better understand the future',
    home: RoutesPathConfig.HOMEANALYSIS,
    redirectMode: 'url',
    icon: BubbleChart,
    qai: 'a'
  },
  {
    name: 'Tariff',
    service: "tariff",
    description: `What is the best price for your policies? Let us help you to find out.`,
    home: RoutesPathConfig.HOMETARIF,
    redirectMode: 'url',
    icon: MdAttachMoney,
    qai: 't',
    disabled: false
  },
  {
    name: 'Admin',
    service: "admin",
    description: 'Manage your users',
    home: RoutesPathConfig.HOMEADMIN,
    redirectMode: 'url',
    icon: FaUserLock,
    qai: 'ad'
  },
  {
    name: 'Estimator',
    service: "estimator",
    description: 'See quotations made by your clients',
    home: RoutesPathConfig.HOMEESTIMATOR,
    redirectMode: 'url',
    icon: FaFileInvoiceDollar,
    qai: 'q'
  },
]} { ...props }/>)