import * as qs from 'qs';
import RestService from '../../../fe-core/service/rest.service';
// import { delay } from 'rxjs/operators';

class UserService extends RestService {
  apiUrl = '/api';

  login(username, password) {
    return this.post(this.apiUrl + '/sessions', {
      username,
      password
    });
  }

  logout() {
    return this.delete(this.apiUrl + '/sessions');
  }

  getCurrentUser() {
    return this.get(this.apiUrl + '/sessions');
  }

  registerUserByInvitation({ username, password, email, invite_token }) {
    return this.post(this.apiUrl + '/users', {
      username,
      password,
      email,
      invite_token
    });
  }

  verifyEmail({ c: code }) {
    return this.get(`${this.apiUrl}/users/verifyemail/${code}`);
  }

  sendAgainVerificationEmail() {
    return this.post(`${this.apiUrl}/users/verifyemail/`);
  }

  sendResetPassword({ email }) {
    return this.post(`${this.apiUrl}/users/resetpassword/`, { email });
  }

  verifyResetPassword({ c: code, i: userid }) {
    return this.get(
      `${this.apiUrl}/users/resetpassword/?` + qs.stringify({ code, userid })
    );
  }

  resetPassword({ c: code, i: userid, password }) {
    return this.put(`${this.apiUrl}/users/resetpassword/`, {
      code,
      userid,
      password
    });
  }

  handleResponseError(e) {
    // 401 is handled by user reducer
    // eslint-disable-next-line eqeqeq
    if (e.status && e.status == 401) {
      return;
    }

    super.handleResponseError(e);
  }
}

export default new UserService();
