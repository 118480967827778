import React from 'react'

const copyIfObject = val => {
  if (typeof val === 'object' && Object.keys(val).length > 0) {
    return Object.assign({}, val)
  }
  return val
}

const transformObjectWithSchema = (obj, schema) => {
  if (typeof obj !== 'object') {
    if (obj === null) {
      return schema
    }

    return obj
  }

  const newObject = Object.keys(schema).reduce((acc, key) => {
    if (obj) {
      // check key existance inside object...

      // if not present set value from schema
      if (!(key in obj)) {
        acc[key] = copyIfObject(schema[key])
        return acc
      }

      // if present ...

      // with not valid value set the value from schema
      if (obj[key] === null) {
        acc[key] = copyIfObject(schema[key])
        return acc
      }

      // and in schema there is another schema
      if (
        typeof schema[key] === 'object' &&
        Object.keys(schema[key]).length > 0
      ) {
        acc[key] = transformObjectWithSchema(obj[key], schema[key])
        return acc
      }

      acc[key] = obj[key]
      return acc
    }

    return acc
  }, copyIfObject(obj))

  return newObject
}

export default ({ props: requiredProps = {} }) => WrappedComponent => props => {
  const newProps = transformObjectWithSchema(props, requiredProps)

  return <WrappedComponent {...newProps} />
}
