import { Base64 } from 'js-base64'

/**
 * @description Decode a base64 encoded string to a UTF-8 rapresentation, it uses js-base64 library
 */
export function decode(input) {
  return Base64.decode(input)
}

/**
 * @description Encode a string to an ascii base64 rapresentation, it uses js-base64 library
 */
export function encode(input) {
  return Base64.encode(input)
}

export default {
  decode,
  encode
}
