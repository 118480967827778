import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { onlyUpdateForKeys, compose } from 'recompose';
import { hoc, components, containers, state } from '../../fe-core';

import RoutesPathConfig from './../../configs/routes';
import UserActions from '../user/actions/user';

// FIXME
// import './App.css';

// FIXME
// import Header from '../containers/Header';

import Login from '../../routes/Login';
import Register from '../../routes/Register';
import RegisterByInvite from '../../routes/RegisterByInvite';
import RegisterSuccess from '../../routes/RegisterSuccess';
import Confirm from '../../routes/Confirm';
import Recovery from '../../routes/Recovery';
import RecoveryDo from '../../routes/RecoveryDo';
import VerifyEmail from '../../routes/VerifyEmail';
import VerifyEmailError from '../../routes/VerifyEmailError';
import PrivateRoute from '../../routes/PrivateRoute';
import VerifyRoute from '../../routes/VerifyRoute';
import NoMatch from '../../routes/NoMatch';
import Home from '../../routes/Home';

//import SelectApp from '../../routes/SelectApp';

const { PageContainer, ErrorComponent, AppContainer } = components;
const { ErrorHandler, SinkOnErrorFactory } = containers;
const { connect } = state.RxState;
const { withStartAction, withErrorBoundary, withLoading } = hoc;

const SinkOnError = SinkOnErrorFactory(UserActions)
const Root = withLoading(props => props.loading)(props => props.children);

const App = ({ user }) => {
  return (
    <AppContainer>
      <ErrorHandler />
      <SinkOnError />
      <Root loading={user.loading !== false}>
        {/* FIXME <Header user={user} /> */}
        <PageContainer>
          <Switch>
            <Route user={user} exact path="/login" component={Login} />
            <Route
              user={user}
              exact
              path={RoutesPathConfig.REGISTER}
              component={Register}
            />
            <Route
              user={user}
              exact
              path={RoutesPathConfig.REGISTERINVITE}
              component={RegisterByInvite}
            />
            <Route
              user={user}
              exact
              path={RoutesPathConfig.REGISTEROK}
              component={RegisterSuccess}
            />
            <Route
              user={user}
              exact
              path={RoutesPathConfig.REGISTERFORGETPW}
              component={Recovery}
            />
            <Route
              user={user}
              exact
              path={RoutesPathConfig.REGISTERFORGETPWDO}
              component={RecoveryDo}
            />
            <VerifyRoute
              user={user}
              exact
              path={RoutesPathConfig.REGISTERCHECKEM}
              component={VerifyEmail}
            />
            <VerifyRoute
              user={user}
              exact
              path={RoutesPathConfig.REGISTERCONFIRM}
              component={Confirm}
            />
            <VerifyRoute
              user={user}
              exact
              path={RoutesPathConfig.REGISTERCHECKEMERR}
              component={VerifyEmailError}
            />
            {/* <PrivateRoute
              user={user}
              exact
              path={RoutesPathConfig.ROOTSELECTAPP}
              component={SelectApp}
            /> */}
            <PrivateRoute
              user={user}
              exact
              path={RoutesPathConfig.ROOTSELECTAPP}
              component={Home}
            />
            
            
            
            <Route
              user={user}
              component={NoMatch}
              path={RoutesPathConfig.NOMATCHCATCHALL}
            />
          </Switch>
        </PageContainer>
      </Root>
    </AppContainer>
  );
};




const userPropForChildren = (user = {}, register = {}) => ({
  isLogged: user.isLogged,
  isConfirmed: user.isConfirmed || register.isConfirmed,
  username: user.username,
  loading: user.loading,
  services: user.services
});

const mapper = state => ({
  user: userPropForChildren(state.user, state.register),
  appError: state.appError
});
const actions = { ...UserActions };

export default compose(
  connect(mapper, actions),
  onlyUpdateForKeys(['user', 'appError']),
  withStartAction(({ loadUserData }) => loadUserData()),
  withErrorBoundary(ErrorComponent)
)(App);
