import { createSink } from 'recompose'
import { AuthorizationError, ClientError } from '../lib/errorUtil'

const redirectPath = pathinfo => {
  // example pathname: "/analysis/models/3ec5479a8a394f609d14282b7e50951eaa"

  const pathClean = pathinfo.replace(/\/+$/, '')
  if (pathClean.split('/').length <= 3) {
    return false
  }

  const upToLastSlash =
    pathinfo
      .replace(/\/+$/, '')
      .split('/')
      .slice(0, -1)
      .join('/') + '/'

  return upToLastSlash
}

const SinkOnError = createSink(({ appError, logout, location, history }) => {
  if (appError instanceof AuthorizationError) {
    logout()
  }

  if (appError instanceof ClientError) {
    if (appError.httpCode === 404 && location && location.pathname) {
      const newPath = redirectPath(location.pathname)
      history.replace(newPath)
    }
  }
})

export default SinkOnError
