import {
  withStateHandlers,
  lifecycle,
  compose,
  branch,
  renderComponent
} from 'recompose'
import * as Sentry from '@sentry/browser'

import DefaultErrorComponent from '../components/ErrorComponent'

export default (config = {}, ErrorComponent = DefaultErrorComponent) =>
  compose(
    withStateHandlers(
      () => ({
        error: null,
        errorInfo: null
      }),
      {
        setError: props => ({ error, errorInfo }) => {
          return {
            ...props,
            error,
            errorInfo
          }
        },
        clearError: props => () => {
          return {
            ...props,
            error: null,
            errorInfo: null
          }
        }
      }
    ),
    lifecycle({
      componentDidCatch(error, errorInfo) {
        if (config.environment !== 'dev') {
          Sentry.configureScope(scope => {
            Object.keys(errorInfo).forEach(key => {
              scope.setExtra(key, errorInfo[key])
            })
          })
          Sentry.captureException(error)
        }
        this.props.setError({ error, errorInfo })
      }
    }),
    branch(props => props.error, renderComponent(ErrorComponent))
  )
