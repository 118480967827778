import Rx from 'rxjs';
import UserReducer$ from './UserReducer';
import RegisterReducer$ from './RegisterReducer';

const reducer$ = Rx.Observable.merge(
  UserReducer$.map(reducer => ['user', reducer]),
  RegisterReducer$.map(reducer => ['register', reducer])
);

export default reducer$;
