import { omit, transform, isEqual, pick, get, isEmpty, isString, isObject } from 'lodash'

export {
  omit,
  pick,
  get,
  isEqual,
  isEmpty,
  isString,
  isObject,
  transform
}

export default {
  omit,
  pick,
  get,
  isEqual,
  isEmpty,
  isString,
  isObject,
  transform
}
